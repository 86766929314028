import { useEffect, useState } from 'react';
import Customer from '../../../interfaces/customer';
import axios, { AxiosResponse } from 'axios';
import {
    Alert,
    Badge,
    Button,
    Card,
    Form,
    Input,
    Modal,
    Select,
    message,
} from 'antd';

import { EditOutlined, LockOutlined } from '@ant-design/icons';

type Props = {
    customer: Customer;
};

interface PlayerLink {
    apple_podcasts: string;
    youtube_music: string;
    spotify: string;
    castro: string;
    downcast: string;
    overcast: string;
    pocket_casts: string;
    podcast_addict: string;
    podcast_republic: string;
    podkicker: string;
    rss_radio: string;
}

interface TransistorUser {
    id: string;
    type: 'subscriber';
    attributes: {
        email: string;
        status: string;
        feed_url: string;
        created_at: string;
        updated_at: string;
        last_notified_at: string;
        has_downloads: boolean;
        subscribe_url: string;
    };
    relationships: {
        show: {
            data: {
                id: string;
                type: string;
            };
        };
    };
}

interface TransistorResponse {
    data?: TransistorUser[];
    meta?: {
        currentPage: number;
        totalPages: number;
        totalCount: number;
    };
    errors?: {
        title: string;
    }[];
}

const getMembershipStatus = (customer: Customer) => {
    return axios.get(
        '/efni/get_transistor_user_from_customer_uuid/' + customer.uuid
    );
};

export default function TransistorDetails({ customer }: Props) {
    const [loading, setLoading] = useState(true);

    const [changeMembershipStatusModal, setChangeMembershipStatusModal] =
        useState<false | { customer: Customer; status: string }>(false);
    const [transistortUser, setTransistorUser] = useState<TransistorUser>();

    useEffect(() => {
        getMembershipStatus(customer)
            .then((rsp: AxiosResponse<TransistorResponse>) => {
                if (rsp.data?.data && (rsp.data?.data?.length || 0) > 0) {
                    setTransistorUser(rsp.data.data[0]);
                }

                setLoading(false);
            })
            .catch((err) => {
                message.error(
                    'Eitthvað fór úrskeiðis í samskiptum við Transistor :/'
                );
                setLoading(false);
            });
    }, []);

    const isActive = !!transistortUser;

    return (
        <>
            <Card
                loading={loading}
                title={
                    <div>
                        <img
                            style={{
                                width: 100,

                                marginRight: 3,
                            }}
                            src="/static/images/global_logos/transistor.png"
                            alt="Transistor"
                        />{' '}
                    </div>
                }
                headStyle={{
                    textAlign: 'center',
                }}
                bodyStyle={{
                    padding: 20,
                    paddingTop: 0,
                }}
            >
                <h4 className="text-center">
                    <div>
                        <Badge status={isActive ? 'success' : 'error'} />{' '}
                        Áskrift {isActive ? '' : 'ó'}
                        virk{' '}
                        <Button
                            size="small"
                            type="text"
                            onClick={() => {
                                setChangeMembershipStatusModal({
                                    customer,
                                    status: isActive ? 'cancelled' : 'active',
                                });
                            }}
                        >
                            <EditOutlined></EditOutlined>
                        </Button>
                    </div>
                </h4>
            </Card>

            <Modal
                open={!!changeMembershipStatusModal}
                onCancel={() => setChangeMembershipStatusModal(false)}
                title="Breyta stöðu Transistor áskriftar"
                footer={false}
            >
                {changeMembershipStatusModal && (
                    <Form
                        size="large"
                        layout="vertical"
                        onFinish={(values) => {
                            setLoading(true);
                            axios
                                .post(
                                    '/efni/change_transistor_status/' +
                                        customer.uuid +
                                        '/',
                                    {
                                        status: values.status,
                                    }
                                )
                                .then((res) => {
                                    message.success('Tókst, stöðu breytt!');
                                    setChangeMembershipStatusModal(false);

                                    getMembershipStatus(customer)
                                        .then(
                                            (
                                                rsp: AxiosResponse<TransistorResponse>
                                            ) => {
                                                if (
                                                    rsp.data?.data &&
                                                    (rsp.data?.data?.length ||
                                                        0) > 0
                                                ) {
                                                    setTransistorUser(
                                                        rsp.data.data[0]
                                                    );
                                                } else {
                                                    setTransistorUser(
                                                        undefined
                                                    );
                                                }
                                                setLoading(false);
                                            }
                                        )
                                        .catch((err) => {
                                            message.error(
                                                'Eitthvað fór úrskeiðis :/'
                                            );
                                        });
                                })
                                .catch((err) => {
                                    message.error('Eitthvað fór úrskeiðis :/');
                                    setLoading(false);
                                });
                        }}
                    >
                        <Form.Item
                            initialValue={
                                changeMembershipStatusModal.status === 'active'
                                    ? 'cancelled'
                                    : 'active'
                            }
                            label="Breyta í"
                            name="status"
                        >
                            <Select
                                options={[
                                    {
                                        label: (
                                            <>
                                                <Badge
                                                    status="success"
                                                    text="Virk"
                                                />
                                            </>
                                        ),
                                        value: 'active',
                                    },
                                    {
                                        label: (
                                            <>
                                                <Badge
                                                    status="error"
                                                    text="Óvirk"
                                                />
                                            </>
                                        ),
                                        value: 'cancelled',
                                    },
                                ]}
                            />
                        </Form.Item>

                        <Button
                            disabled={loading}
                            type="primary"
                            htmlType="submit"
                            block
                        >
                            Staðfesta
                        </Button>
                    </Form>
                )}
            </Modal>
        </>
    );
}
