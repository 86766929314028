import { useState, useEffect, useContext } from 'react';
import dayjs from 'dayjs';
import axios from 'axios';
import {
    Popover,
    Table,
    Button,
    Layout,
    Card,
    Collapse,
    Modal,
    Dropdown,
    Badge,
    message,
    Checkbox,
    Slider,
    DatePicker,
    Tooltip,
} from 'antd';
import {
    CheckOutlined,
    DownOutlined,
    EditOutlined,
    QuestionCircleTwoTone,
    FileExcelOutlined,
    CaretDownOutlined,
    CloseOutlined,
    CommentOutlined,
    DeleteOutlined,
    CreditCardOutlined,
    FilterFilled,
    MailOutlined,
} from '@ant-design/icons';

import {
    getCronLabel,
    getAvailablePaymentMethods,
    displayCurrencyPrice,
} from '../../utils';

import {
    SubscriptionTransaction,
    SubscriptionTransactionFilter,
    SubscriptionTransactionFilterRequest,
} from '../../interfaces/transaction';
import TransactionPaymentStatus from '../../components/TransactionPaymentStatus/TransactionPaymentStatus';
import Product from '../../interfaces/product';
import EditSubscriptionTransactions from './EditSubscriptionTransactions/EditSubscriptionTransactions';
// import CreateClaimsFile from './CreateClaimsFile/CreateClaimsFile';
import ChargeTransactionsModal from './ChargeTransactionsModal/ChargeTransactionsModal';
import brandingConfig from '../../utils/brandingConfig';
import ConvertPaymentMethod from './ConvertPaymentMethod/ConvertPaymentMethod';
import EmailModal from '../../components/EmailModal/EmailModal';
import ExcelModal from '../../components/ExcelModal/ExcelModal';
import RefundCardsModal from './RefundCardsModal/RefundCardsModal';
import AuthContext from '../../authContext';
import TextArea from 'antd/lib/input/TextArea';
import CancelSubscriptionTransactionsModal from './CancelSubscriptionTransactionsModal/CancelSubscriptionTransactionsModal';

dayjs.locale('is'); // use locale

interface IWindow extends Window {
    uuid?: string;
}

const w: IWindow = window;
const { Content } = Layout;
const pageSize = 100;
const { Panel } = Collapse;
const availableCardMethods = getAvailablePaymentMethods('cards');

export default function SubscriptionTransactionOverview() {
    const { selectedShop } = useContext(AuthContext);

    const availableCardPaymentMethods = getAvailablePaymentMethods('cards');

    const [allSelected, setAllSelected] = useState<boolean>(false);
    const [selectionList, setSelectionList] = useState<number[]>([]);
    const [selectedCount, setSelectedCount] = useState(0);
    const [selectedPage, setSelectedPage] = useState(1);
    const [subscriptionTransactions, setSubscriptionTransactions] = useState<
        SubscriptionTransaction[]
    >([]);
    const [disableSubscriptionsModal, setDisableSubscriptionsModal] =
        useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [emailModal, setEmailModal] = useState(false);
    const [filters, setFilters] = useState<SubscriptionTransactionFilter>({
        is_completed: false,
        is_uncompleted: true,
        subscription__active: '',
        is_free: '',
        ordering: '-created',
        subscription__product__in: '',
        subscription__payment_method__in: [
            availableCardMethods,
            'BANK_CLAIM',
        ].join(','),

        frequencies: '',
        max_per_customer: '',
        min_per_customer: '',
        created_from: '',
        created_to: '',
    });
    const [
        subscriptionTransactionFilterRequest,
        setSubscriptionTransactionFilterRequest,
    ] = useState<SubscriptionTransactionFilterRequest>();

    const [convertPaymentMethodModal, setConvertPaymentMethodModal] =
        useState(false);

    const [products, setProducts] = useState<Product[]>([]);
    const [availableFrequencies, setAvailableFrequencies] = useState<
        {
            interval: number;
            interval_type: 'MONTH' | 'WEEK' | 'DAY' | 'YEAR';
        }[]
    >([]);
    const [excelModal, setExcelModal] = useState(false);

    const [maxSliderCount, setMaxSliderCount] = useState<number>();

    const [markAsFulfilledModal, setMarkAsFulfilledModal] = useState(false);
    const [markAsPaidModal, setMarkAsPaidModal] = useState(false);
    const [commentModal, setCommentModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [refetch, setRefetch] = useState(true);
    const [chargeCardsModal, setChargeCardsModal] = useState(false);
    const [refundCardsModal, setRefundCardsModal] = useState(false);
    const [paymentFilters, setPaymentFilters] = useState([]);

    const somethingSelected = () => {
        return !!subscriptionTransactionFilterRequest?.selectedCount;
    };

    useEffect(() => {
        if (!selectedShop) {
            return;
        }

        axios
            .get(`/verslun/api/get_shop_payment_filters/${selectedShop.uuid}/`)
            .then((rsp) => {
                const data = rsp.data;

                setPaymentFilters(data);
            });

        axios
            .get(
                '/verslun/api/product/?type=SUBSCRIPTION&shop__uuid=' +
                    selectedShop.uuid
            )
            .then((rsp) => {
                setProducts(rsp.data.results);
            });

        axios
            .post('/askrift/api/get_shop_frequenies/', {
                shop_uuid: selectedShop.uuid,
            })
            .then((rsp) => {
                const data = rsp.data.data || [];
                setAvailableFrequencies(data);
            });
    }, [selectedShop]);

    useEffect(() => {
        if (!selectedShop) {
            return;
        }
        setSubscriptionTransactionFilterRequest({
            filters,
            allSelected,
            selectedCount,
            selectionList,
            shop_uuid: selectedShop.uuid || '',
        });
    }, [filters, selectedCount, allSelected, selectionList, selectedShop]);

    useEffect(() => {
        setAllSelected(false);
        setSelectionList([]);
    }, [filters, refetch]);

    useEffect(() => {
        setLoading(true);

        if (!selectedShop) {
            return;
        }

        axios
            .get(
                `/askrift/api/subscription_transactions/?custom_filters=true&created_from=${filters.created_from}&created_to=${filters.created_to}&max_per_customer=${filters.max_per_customer}&min_per_customer=${filters.min_per_customer}&page_size=${pageSize}&subscription__active=${filters.subscription__active}&frequencies=${filters.frequencies}&ordering=${filters.ordering}&page=${selectedPage}&subscription__product__shop__uuid=${selectedShop?.uuid}&is_uncompleted=${filters.is_uncompleted}&is_completed=${filters.is_completed}&subscription__product__in=${filters.subscription__product__in}&ordering=${filters.ordering}&subscription__payment_method__in=${filters.subscription__payment_method__in}`
            )
            .then((rsp) => {
                setSubscriptionTransactions(rsp.data.results);
                setCount(rsp.data.count);

                if (rsp.data.max_customer_transaction_count) {
                    setMaxSliderCount(rsp.data.max_customer_transaction_count);
                }
                setLoading(false);
                // setRefetching(false);
                // setSelectedRowKeys([]);
            });
    }, [filters, selectedPage, refetch, selectedShop]);

    useEffect(() => {
        setRowSelections();
    }, [subscriptionTransactions, selectionList]);

    // Setur SelectedCount til að sýna notandanum hvað hann er með margar sendingar valdar
    useEffect(() => {
        setSelectedCount(
            allSelected
                ? (count || 0) - selectionList.length
                : selectionList.length
        );
    }, [allSelected, selectionList]);

    const setRowSelections = () => {
        if (allSelected) {
            setSelectedRowKeys(
                subscriptionTransactions
                    .map((transaciton) => transaciton.id)
                    .filter((id) => !selectionList.includes(id))
            );
        }

        if (!allSelected) {
            setSelectedRowKeys(selectionList);
        }
    };

    const makeSelectionChange = (rowKeys: number[], selected: boolean) => {
        /* Ef ALLT er valið og eitthvað var "deselectað" */
        if (allSelected && !selected) {
            /* Núna er selection list = excludedUUIDs */
            setSelectionList([...new Set([...selectionList, ...rowKeys])]);
        }

        /* Ef ALLT er valið og eitthvað var selectað */
        if (allSelected && selected) {
            /* Núna er selection list = excludedUUIDs */
            setSelectionList(
                selectionList.filter((uuid) => !rowKeys.includes(uuid))
            );
        }

        /* Ef það er ekki "hakað við" ALLT og eitthvað var selectað */
        if (!allSelected && selected) {
            /* Núna er selection list = Valin UUIDs */
            setSelectionList([...new Set([...selectionList, ...rowKeys])]);
        }

        /* Ef það er ekki "hakað við" ALLT og eitthvað var deselectað */
        if (!allSelected && !selected) {
            /* Núna er selection list = Valin UUIDs */
            setSelectionList(
                selectionList.filter((uuid) => !rowKeys.includes(uuid))
            );
        }
    };

    let columns = [
        {
            title: 'Nafn',
            key: 'subscription_active',
            filters: [
                {
                    value: 'true',
                    text: 'Áskrift virk',
                },
                {
                    value: 'false',
                    text: 'Áskrift óvirk',
                },
            ],
            render: (data: SubscriptionTransaction) => {
                const color =
                    data.subscription.active && data.subscription.resign_date
                        ? 'yellow'
                        : undefined;

                let status = (
                    data.subscription.active ? 'success' : 'error'
                ) as undefined | 'success' | 'error';

                if (color) status = undefined;

                return (
                    <Popover
                        content={
                            <>
                                <div>
                                    <Badge
                                        status="success"
                                        text="Áskrift er virk"
                                    />
                                </div>
                                <div>
                                    <Badge
                                        status="error"
                                        text="Áskrift er óvirk"
                                    />
                                </div>
                                <div>
                                    <Badge
                                        color="yellow"
                                        text="Áskrift er virk en hefur verið sagt upp og verður sjálfkrafa óvirkjuð í framtíðinni"
                                    />
                                </div>
                            </>
                        }
                    >
                        <Badge
                            color={color}
                            status={status}
                            text={
                                <a
                                    className="capitalize"
                                    style={{
                                        color: 'rgba(0, 0, 0, 0.65)',
                                    }}
                                    href={`/vidskiptavinur/${data.subscription.customer.uuid}/`}
                                >
                                    {data.subscription.customer?.name}
                                </a>
                            }
                        />
                    </Popover>
                );
            },
        },
        {
            title: 'Áskrift',
            key: 'tegund_askriftar',
            filters: products.map((product) => {
                return {
                    value: product.id,
                    text: product.title,
                };
            }),
            render: (subscriptionTransaction: SubscriptionTransaction) => {
                return subscriptionTransaction.subscription.product.title;
            },
        },
        {
            title: 'Tíðni',
            key: 'frequency',
            className: 'frequencyInfo',
            filters: availableFrequencies.map((obj) => {
                return {
                    value: obj.interval + '_' + obj.interval_type,
                    text: getCronLabel(obj.interval, obj.interval_type),
                };
            }),
            render: (subscriptionTransaction: SubscriptionTransaction) => {
                const subscription = subscriptionTransaction.subscription;
                const cronSettings = subscription.subscription_product
                    .cron_transaction
                    ? subscription.subscription_product.cron_transaction
                    : subscription.product.cron_transaction;

                if (!cronSettings) {
                    return 'Ekki virkt';
                } else {
                    return getCronLabel(
                        cronSettings.interval || 1,
                        cronSettings.interval_type,
                        subscription.product
                    );
                }
            },
        },
        {
            title: 'Greitt',
            key: 'paid',
            render: (subscriptionTransaction: SubscriptionTransaction) => {
                return (
                    <div>
                        <Tooltip
                            title={
                                !subscriptionTransaction.is_deleted &&
                                subscriptionTransaction.is_paid &&
                                subscriptionTransaction.payment_received_date
                                    ? dayjs(
                                          subscriptionTransaction.payment_received_date
                                      ).format('D. MMM YYYY - HH:mm')
                                    : ''
                            }
                        >
                            <span>
                                <TransactionPaymentStatus
                                    transaction={subscriptionTransaction}
                                />
                            </span>
                        </Tooltip>
                    </div>
                );
            },
        },
        {
            title: 'Upphæð',
            key: 'amount',
            render: (subscriptionTransaction: SubscriptionTransaction) => {
                return displayCurrencyPrice(
                    subscriptionTransaction.amount || 0,
                    subscriptionTransaction.currency_code
                );
            },
        },
        {
            title: 'Uppfyllt',
            key: 'fulfilled',
            render: (transaction: SubscriptionTransaction) => {
                return (
                    <>
                        {transaction.shopify_order_id && (
                            <img
                                src="/static/images/svg/shopify.svg"
                                alt=""
                                style={{
                                    width: 15,
                                    marginRight: 10,
                                    position: 'relative',
                                    top: -2,
                                }}
                            />
                        )}
                        {transaction.is_fulfilled ? (
                            <Badge status="success" text="Já" />
                        ) : (
                            <Badge status="error" text="Nei" />
                        )}
                    </>
                );
            },
        },
        {
            title: 'Dagsetning',
            key: 'date',
            SortOrder: ['ascend', 'descend'],
            sorter: {},
            filterIcon: () => (
                <FilterFilled
                    style={{
                        color:
                            filters.created_from || filters.created_to
                                ? '#FF7276'
                                : 'inherit',
                    }}
                />
            ),
            filterDropdown: () => (
                <div style={{ padding: 8 }}>
                    <DatePicker.RangePicker
                        onChange={(v) => {
                            if (!v) {
                                setFilters({
                                    ...filters,
                                    created_from: '',
                                    created_to: '',
                                });
                            } else {
                                setFilters({
                                    ...filters,
                                    created_from: v[0]
                                        ?.startOf('day')
                                        .toISOString(),
                                    created_to: v[1]
                                        ?.endOf('day')
                                        .toISOString(),
                                });
                            }
                        }}
                    />
                </div>
            ),
            render: (transaction: SubscriptionTransaction) => {
                return (
                    <div>
                        {dayjs(transaction.created).format(
                            'D. MMM YYYY - HH:mm'
                        )}
                    </div>
                );
            },
        },
        {
            title: 'Greiðsluleið',
            key: 'payment_method',
            filters: paymentFilters,
            render: (transaction: SubscriptionTransaction) => {
                const paymentMethod =
                    transaction.payment_method ||
                    transaction.subscription.payment_method;

                const subscription = transaction.subscription;
                let payment_string = '';
                if (paymentMethod === 'BANK_CLAIM') {
                    payment_string = 'Krafa';
                } else if (
                    paymentMethod === 'CARD' ||
                    availableCardPaymentMethods.includes(paymentMethod)
                ) {
                    payment_string = 'Kort';
                } else if (paymentMethod === 'PAYDAY') {
                    payment_string = 'Payday';
                }

                return subscription.type.title === 'Gjafabréf'
                    ? subscription.type.title
                    : payment_string;
            },
        },
    ];

    if (availableFrequencies.length < 2) {
        columns = columns.filter((column) => column.key !== 'frequency');
    }

    return (
        <>
            <Card
                className="basePageCard"
                title={
                    <>
                        <img
                            style={{ height: 35, marginRight: 10 }}
                            src="/static/images/svg/box.svg"
                            alt=""
                        />{' '}
                        Sendingar ({count})
                    </>
                }
            >
                <Card
                    title={
                        <div>
                            <Dropdown
                                menu={{
                                    items: [
                                        {
                                            label: 'Merkja sem greitt/ógreitt',
                                            icon: <CheckOutlined />,
                                            key: 'paid',
                                            onClick: () => {
                                                if (somethingSelected()) {
                                                    setMarkAsPaidModal(true);
                                                } else {
                                                    message.error(
                                                        'Þú verður að velja að lágmarki eina sendingu.'
                                                    );
                                                }
                                            },
                                        },
                                        {
                                            label: 'Merkja sem uppfyllt/óuppfyllt',
                                            icon: (
                                                <img
                                                    src="/static/images/svg/box.svg"
                                                    alt="Box"
                                                    style={{
                                                        width: 16,
                                                    }}
                                                />
                                            ),
                                            key: 'fulfilled',
                                            onClick: () => {
                                                if (somethingSelected()) {
                                                    setMarkAsFulfilledModal(
                                                        true
                                                    );
                                                } else {
                                                    message.error(
                                                        'Þú verður að velja að lágmarki eina sendingu.'
                                                    );
                                                }
                                            },
                                        },
                                        {
                                            label: 'Bæta við athugasemd',
                                            icon: <CommentOutlined />,
                                            key: 'comment',
                                            onClick: () => {
                                                if (somethingSelected()) {
                                                    setCommentModal(true);
                                                } else {
                                                    message.error(
                                                        'Þú verður að velja að lágmarki eina sendingu.'
                                                    );
                                                }
                                            },
                                        },
                                        {
                                            label: 'Rukka sendingar',
                                            icon: <CreditCardOutlined />,
                                            key: 'charge_cards',
                                            onClick: () => {
                                                const some_selected =
                                                    !!selectedRowKeys.length;

                                                if (!some_selected) {
                                                    message.error(
                                                        'Þú verður að velja lágmark eina sendingu'
                                                    );
                                                    return;
                                                }
                                                setChargeCardsModal(true);
                                            },
                                        },
                                        {
                                            label: 'Endurgreiða kortagreiðslur',
                                            icon: <CreditCardOutlined />,
                                            key: 'refund_cards',
                                            onClick: () => {
                                                const some_selected =
                                                    !!selectedRowKeys.length;

                                                if (!some_selected) {
                                                    message.error(
                                                        'Þú verður að velja lágmark eina sendingu'
                                                    );
                                                    return;
                                                }
                                                setRefundCardsModal(true);
                                            },
                                        },
                                        {
                                            label: 'Breyta greiðslumáta í kröfu',
                                            icon: <EditOutlined />,
                                            key: 'charge_payment_method',
                                            onClick: () => {
                                                const some_selected =
                                                    !!selectedRowKeys.length;

                                                if (!some_selected) {
                                                    message.error(
                                                        'Þú verður að velja lágmark eina sendingu'
                                                    );
                                                    return;
                                                }
                                                setConvertPaymentMethodModal(
                                                    true
                                                );
                                            },
                                        },
                                        {
                                            label: 'Senda tölvupósta',
                                            icon: <MailOutlined />,
                                            key: 'send_emails',
                                            onClick: () => {
                                                const some_selected =
                                                    !!selectedRowKeys.length;

                                                if (!some_selected) {
                                                    message.error(
                                                        'Þú verður að velja lágmark eina sendingu'
                                                    );
                                                    return;
                                                }

                                                setEmailModal(true);
                                            },
                                        },
                                        {
                                            label: 'Sækja Excel skjal',
                                            icon: <FileExcelOutlined />,
                                            key: 'excel',
                                            onClick: () => {
                                                const some_selected =
                                                    !!selectedRowKeys.length;

                                                if (!some_selected) {
                                                    message.error(
                                                        'Þú verður að velja lágmark eina sendingu til að sækja Excel skjal'
                                                    );
                                                    return;
                                                }

                                                setExcelModal(true);
                                            },
                                            style: {
                                                color: '#22532e',
                                            },
                                        },
                                        {
                                            key: 'g1',
                                            label: 'Hættusvæði',
                                            type: 'group',
                                            children: [
                                                {
                                                    label: 'Afvirkja áskriftir',
                                                    icon: <DeleteOutlined />,
                                                    danger: true,
                                                    key: 'cancel_subscriptions',
                                                    onClick: () => {
                                                        if (
                                                            somethingSelected()
                                                        ) {
                                                            setDisableSubscriptionsModal(
                                                                true
                                                            );
                                                        } else {
                                                            message.error(
                                                                'Þú verður að velja að lágmarki eina sendingu.'
                                                            );
                                                        }
                                                    },
                                                },
                                                {
                                                    label: 'Eyða',
                                                    danger: true,
                                                    icon: <DeleteOutlined />,
                                                    key: 'delete',
                                                    onClick: () => {
                                                        if (
                                                            somethingSelected()
                                                        ) {
                                                            setDeleteModal(
                                                                true
                                                            );
                                                        } else {
                                                            message.error(
                                                                'Þú verður að velja að lágmarki eina sendingu.'
                                                            );
                                                        }
                                                    },
                                                },
                                            ],
                                        },
                                    ],
                                }}
                            >
                                <Button type="primary">
                                    Aðgerðir <DownOutlined />
                                </Button>
                            </Dropdown>

                            <span
                                style={{
                                    marginLeft: 8,
                                    marginRight: 8,
                                }}
                            >
                                {allSelected && (
                                    <>
                                        ALLAR síður valdar{' '}
                                        {!!selectionList.length && (
                                            <>
                                                að {selectionList.length}{' '}
                                                sendingum undanskildum (alls:{' '}
                                                {(count || 0) -
                                                    selectionList.length}
                                                )
                                            </>
                                        )}
                                    </>
                                )}

                                {!allSelected && selectedRowKeys.length > 0
                                    ? `${selectedRowKeys.length} ${
                                          selectedRowKeys.length > 1
                                              ? 'valdar áskriftir'
                                              : 'valin áskrift'
                                      }`
                                    : ''}
                            </span>
                        </div>
                    }
                    extra={
                        <div>
                            <div
                                style={{
                                    borderBottom: '1px solid #cacaca',
                                    position: 'relative',
                                    marginBottom: 5,
                                }}
                            >
                                <Popover
                                    content={
                                        <div>
                                            <div>
                                                ATH að áskriftir AFHAKAST þegar
                                                þú breytir síum.
                                            </div>
                                            <div>
                                                Best er að sía fyrst og haka
                                                svo.
                                            </div>
                                        </div>
                                    }
                                >
                                    <QuestionCircleTwoTone
                                        twoToneColor="#afafaf"
                                        style={{
                                            position: 'absolute',
                                            fontSize: 16,
                                            top: 2,
                                        }}
                                    />
                                </Popover>
                                <h4
                                    style={{
                                        fontSize: '16px',
                                        position: 'relative',
                                        left: 20,
                                        marginBottom: 0,
                                    }}
                                >
                                    Síur
                                </h4>
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 20,
                                }}
                            >
                                {maxSliderCount && maxSliderCount > 1 && (
                                    <div style={{ width: 250 }}>
                                        <Collapse
                                            style={{
                                                border: 'none',
                                            }}
                                        >
                                            <Panel
                                                style={{
                                                    background: 'white',
                                                    border: 'none',
                                                }}
                                                header="Ýtarlegri síur"
                                                key="1"
                                            >
                                                <>
                                                    <div
                                                        style={{
                                                            fontSize: 11,
                                                            position:
                                                                'relative',
                                                            paddingLeft: 20,
                                                        }}
                                                    >
                                                        <Popover
                                                            content={
                                                                <div>
                                                                    <p>
                                                                        Gott ef
                                                                        þú vilt
                                                                        t.d
                                                                        birta þá
                                                                        sem eru
                                                                        með
                                                                        fleiri
                                                                        en 2
                                                                        ógreiddar
                                                                        sendingar
                                                                        .
                                                                    </p>
                                                                    <div>
                                                                        ATH að
                                                                        niðurstöðurnar
                                                                        miðast
                                                                        við þær
                                                                        síur sem
                                                                        eru
                                                                        virkar.
                                                                    </div>
                                                                </div>
                                                            }
                                                        >
                                                            <QuestionCircleTwoTone
                                                                twoToneColor="#afafaf"
                                                                style={{
                                                                    position:
                                                                        'absolute',
                                                                    fontSize: 16,
                                                                    top: 0,
                                                                    left: 0,
                                                                }}
                                                            />
                                                        </Popover>{' '}
                                                        Fjöldi sendinga per
                                                        viðskiptavin
                                                    </div>
                                                    <div>
                                                        <Slider
                                                            range
                                                            max={maxSliderCount}
                                                            min={1}
                                                            step={1}
                                                            defaultValue={[
                                                                1, 10,
                                                            ]}
                                                            onAfterChange={(
                                                                val
                                                            ) =>
                                                                setFilters({
                                                                    ...filters,
                                                                    min_per_customer:
                                                                        val[0],
                                                                    max_per_customer:
                                                                        val[1],
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </>
                                            </Panel>
                                        </Collapse>
                                    </div>
                                )}
                                <div>
                                    <div>
                                        <Checkbox
                                            disabled={loading}
                                            onChange={(e) => {
                                                setFilters({
                                                    ...filters,
                                                    is_completed:
                                                        !filters.is_completed,
                                                });
                                            }}
                                            checked={filters.is_completed}
                                        />{' '}
                                        Frágengið
                                    </div>

                                    <div>
                                        <Checkbox
                                            disabled={loading}
                                            onChange={(e) => {
                                                setFilters({
                                                    ...filters,
                                                    is_uncompleted:
                                                        !filters.is_uncompleted,
                                                });
                                            }}
                                            checked={filters.is_uncompleted}
                                        />{' '}
                                        Ófrágengið
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                >
                    <Table
                        loading={loading}
                        className="white_table"
                        // onChange?: (page: number, pageSize: number) => void;
                        // onShowSizeChange?: (current: number, size: number) => void;
                        // itemRender?: (
                        //     page: number,
                        //     type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next',
                        //     element: React.ReactNode,
                        // ) => React.ReactNode;
                        // showTotal?: (total: number, range: [number, number]) => React.ReactNode;

                        pagination={{
                            hideOnSinglePage: true,
                            defaultPageSize: pageSize,
                            showSizeChanger: false,
                            total: count,
                            onChange: (pageNumber: number) => {
                                if (pageNumber !== selectedPage) {
                                    setSelectedPage(pageNumber);
                                }
                            },
                        }}
                        // expandable={{
                        //     expandedRowRender: (record) => (
                        //         <p style={{ margin: 0 }}>
                        //             {record.staff_comments.map((comment) => {
                        //                 return (
                        //                     <Card
                        //                         type="inner"
                        //                         size="small"
                        //                         title={comment.user}
                        //                         extra={dayjs(
                        //                             comment.created
                        //                         ).format('D. MMM YYYY - HH:mm')}
                        //                     >
                        //                         {comment.message}
                        //                     </Card>
                        //                 );
                        //             })}
                        //         </p>
                        //     ),
                        //     rowExpandable: (record) =>
                        //         !!record.staff_comments &&
                        //         !!record.staff_comments.length,
                        // }}
                        onChange={(pagination, filter, sorter, extra) => {
                            const action = extra.action;

                            if (action === 'sort') {
                                let order = Array.isArray(sorter)
                                    ? sorter[0]?.order
                                    : sorter?.order;

                                const ordering =
                                    order === 'ascend' ? 'created' : '-created';

                                setFilters({
                                    ...filters,
                                    ordering,
                                });
                            }

                            if (action === 'filter') {
                                const frequencies = (
                                    filter.frequency || []
                                ).map((x) => {
                                    return {
                                        interval: parseInt(
                                            x.toString().split('_')[0]
                                        ),
                                        interval_type: x
                                            .toString()
                                            .split('_')[1] as
                                            | 'MONTH'
                                            | 'YEAR'
                                            | 'DAY'
                                            | 'WEEK',
                                    };
                                });

                                const productIn = filter.tegund_askriftar
                                    ? filter.tegund_askriftar.join(',')
                                    : '';

                                let paymentType = (
                                    filter.payment_method
                                        ? filter.payment_method.join(',')
                                        : ''
                                ) as SubscriptionTransactionFilter['subscription__payment_method__in'];

                                if (paymentType.includes('SALT_PAY')) {
                                    paymentType = paymentType.replace(
                                        'SALT_PAY',
                                        availableCardMethods.join(',')
                                    );
                                }

                                const subscriptionsActive = (
                                    filter.subscription_active
                                        ? filter.subscription_active
                                              .map((val) => val)
                                              .join(',')
                                        : ''
                                ) as SubscriptionTransactionFilter['subscription__active'];

                                setFilters({
                                    ...filters,
                                    subscription__active: subscriptionsActive,
                                    subscription__product__in: productIn,
                                    subscription__payment_method__in:
                                        paymentType,
                                    frequencies: frequencies
                                        .map(
                                            (obj) =>
                                                obj.interval +
                                                '_' +
                                                obj.interval_type
                                        )
                                        .join(','),
                                });
                                setSelectedRowKeys([]);
                            }
                        }}
                        locale={{
                            emptyText: (
                                <div>
                                    <h2>
                                        Ekkert fannst{' '}
                                        <span role="img" aria-label="emoji">
                                            🫙
                                        </span>
                                    </h2>
                                </div>
                            ),
                        }}
                        rowSelection={{
                            selectedRowKeys,
                            /* onChange: (selectedRowKeys: string[]) => {
                                                setSelectedRowKeys(selectedRowKeys);
                                            }, */

                            onSelectMultiple(
                                selected,
                                selectedRows,
                                changedRows
                            ) {
                                makeSelectionChange(
                                    changedRows.map((o) => o.id),
                                    selected
                                );
                            },
                            onSelect: (obj, selected) => {
                                makeSelectionChange([obj.id], selected);
                            },
                            hideSelectAll: true,
                            columnTitle: (
                                <>
                                    <Dropdown
                                        menu={{
                                            items: [
                                                {
                                                    key: 'check_all',
                                                    label: (
                                                        <div
                                                            onClick={() => {
                                                                setAllSelected(
                                                                    true
                                                                );
                                                                setSelectionList(
                                                                    []
                                                                );
                                                            }}
                                                        >
                                                            <CheckOutlined
                                                                style={{
                                                                    color: brandingConfig.successColor,
                                                                }}
                                                            />{' '}
                                                            Haka við allar síður
                                                        </div>
                                                    ),
                                                },
                                                {
                                                    key: 'uncheck_all',
                                                    label: (
                                                        <div
                                                            onClick={() => {
                                                                setAllSelected(
                                                                    false
                                                                );
                                                                setSelectionList(
                                                                    []
                                                                );
                                                            }}
                                                        >
                                                            <CloseOutlined
                                                                style={{
                                                                    color: brandingConfig.dangerColor,
                                                                }}
                                                            />{' '}
                                                            Afhaka allt
                                                        </div>
                                                    ),
                                                },
                                            ],
                                        }}
                                    >
                                        <Button size="small">
                                            Haka við
                                            <CaretDownOutlined />
                                        </Button>
                                    </Dropdown>
                                </>
                            ),
                        }}
                        dataSource={subscriptionTransactions.map((obj) => {
                            return {
                                ...obj,
                                id: obj.id,
                            };
                        })}
                        rowKey="id"
                        columns={columns}
                    ></Table>
                </Card>

                {/* {subscriptionTransactionFilterRequest && createClaimsModal && (
                <CreateClaimsFile
                    selection={subscriptionTransactionFilterRequest}
                    onSuccess={(rdy) => {
                        setCreateClaimsModal(false);
                    }}
                ></CreateClaimsFile>
            )} */}

                <Modal
                    okText="Já, eyða"
                    open={deleteModal}
                    title="Eyða sendingum?"
                    confirmLoading={deleteLoading}
                    onOk={() => {
                        setDeleteLoading(true);
                        axios
                            .post(
                                '/askrift/update_subscription_transactions/',
                                {
                                    is_deleted: true,
                                    selection:
                                        subscriptionTransactionFilterRequest,
                                }
                            )
                            .then(() => {
                                setDeleteLoading(false);
                                setDeleteModal(false);
                                setRefetch(!refetch);
                                message.success('Tókst');
                            });
                    }}
                >
                    <p style={{ color: 'red' }}>
                        Þú ert að fara að eyða sendingum.
                    </p>
                    <p>Ertu alveg viss? Þessi aðgerð er óafturkræf!</p>

                    <strong>Fjöldi: {selectedCount}</strong>
                </Modal>

                {!!subscriptionTransactionFilterRequest &&
                    (markAsFulfilledModal ||
                        markAsPaidModal ||
                        commentModal) && (
                        <EditSubscriptionTransactions
                            title={`Merkja sem ${
                                markAsFulfilledModal
                                    ? 'afgreitt/óafgreitt'
                                    : 'greitt/ógreitt'
                            }`}
                            options={{
                                mark_fulfilled: markAsFulfilledModal,
                                mark_paid: markAsPaidModal,
                                comment: true,
                            }}
                            open={!!subscriptionTransactionFilterRequest}
                            filterRequest={subscriptionTransactionFilterRequest}
                            onCancel={() => {
                                setMarkAsFulfilledModal(false);
                                setMarkAsPaidModal(false);
                                setCommentModal(false);
                            }}
                            onSuccess={() => {
                                setMarkAsFulfilledModal(false);
                                setMarkAsPaidModal(false);
                                setCommentModal(false);
                            }}
                        ></EditSubscriptionTransactions>
                    )}

                {chargeCardsModal && subscriptionTransactionFilterRequest && (
                    <ChargeTransactionsModal
                        onCancel={() => {
                            setChargeCardsModal(false);
                        }}
                        selection={subscriptionTransactionFilterRequest}
                        open={chargeCardsModal}
                        onSuccess={() => {
                            setRefetch(!refetch);
                            setChargeCardsModal(false);
                            message.success(
                                'Tókst, sendingar sendar í greiðsluferli.'
                            );
                        }}
                    ></ChargeTransactionsModal>
                )}

                {refundCardsModal && subscriptionTransactionFilterRequest && (
                    <RefundCardsModal
                        onCancel={() => {
                            setRefundCardsModal(false);
                        }}
                        selection={subscriptionTransactionFilterRequest}
                        open={refundCardsModal}
                        onSuccess={() => {
                            setRefetch(!refetch);
                            setRefundCardsModal(false);
                            message.success(
                                'Tókst, sendingar sendar í endurgreiðsluferli.'
                            );
                        }}
                    ></RefundCardsModal>
                )}

                {convertPaymentMethodModal &&
                    subscriptionTransactionFilterRequest && (
                        <Modal
                            title={'Breyta  í kröfu'}
                            open={convertPaymentMethodModal}
                            footer={false}
                            onCancel={() => setConvertPaymentMethodModal(false)}
                        >
                            <ConvertPaymentMethod
                                closeModal={() =>
                                    setConvertPaymentMethodModal(false)
                                }
                                selection={subscriptionTransactionFilterRequest}
                            ></ConvertPaymentMethod>
                        </Modal>
                    )}

                {!!emailModal && selectedShop && (
                    <EmailModal
                        dataType="SUBSCRIPTION_TRANSACTION_FILTERS"
                        subscriptionTransactionFilterPayload={
                            subscriptionTransactionFilterRequest
                        }
                        selectedCount={selectedCount}
                        onCancel={() => {
                            setEmailModal(false);
                        }}
                        open={!!emailModal}
                        shop={selectedShop.uuid}
                    ></EmailModal>
                )}

                <ExcelModal
                    open={excelModal}
                    onClose={() => setExcelModal(false)}
                    subscriptionTransactionFilterPayload={
                        subscriptionTransactionFilterRequest
                    }
                ></ExcelModal>

                <Modal
                    footer={false}
                    open={disableSubscriptionsModal}
                    onCancel={() => setDisableSubscriptionsModal(false)}
                    title="Afvirkja áskriftir?"
                >
                    <p>
                        Ertu viss um að þú viljir afvirkja allar áskriftir
                        tengdar völdum sendingum?
                    </p>

                    <TextArea
                        placeholder="Skýring"
                        rows={6}
                        style={{ marginBottom: 20 }}
                    ></TextArea>

                    <Button
                        type="primary"
                        size="large"
                        block
                        onClick={() => {
                            axios
                                .post(
                                    '/askrift/update_subscription_transactions/',
                                    {
                                        is_active: false,
                                        selection:
                                            subscriptionTransactionFilterRequest,
                                    }
                                )
                                .then(() => {
                                    setDisableSubscriptionsModal(false);
                                    setRefetch(!refetch);
                                    message.success('Tókst');
                                });
                        }}
                    >
                        Afvirkja áskriftir
                    </Button>
                </Modal>
            </Card>

            {disableSubscriptionsModal &&
                subscriptionTransactionFilterRequest && (
                    <CancelSubscriptionTransactionsModal
                        open={disableSubscriptionsModal}
                        selection={subscriptionTransactionFilterRequest}
                        onSuccess={() => setDisableSubscriptionsModal(false)}
                        onCancel={() => setDisableSubscriptionsModal(false)}
                    ></CancelSubscriptionTransactionsModal>
                )}
        </>
    );
}
