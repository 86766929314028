import { useState } from 'react';
import { Button, Dropdown, message, Modal } from 'antd';
import {
    CopyOutlined,
    EditOutlined,
    EyeOutlined,
    Html5Outlined,
} from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CopyIframeCodeModal from '../CopyIframeCodeModal/CopyIframeCodeModal';
import CreatePaymentLink from '../CreatePaymentLink/CreatePaymentLink';
import Product from '../../interfaces/product';

type Props = {
    product: Product;
    hideMainButton?: boolean;
};

export default function ProductDropdownOptions({
    product,
    hideMainButton,
}: Props) {
    const [clipboardModal, setClipboardModal] = useState<
        { uuid: string; type: 'gift_certificate' | 'product' } | false
    >(false);

    const [createPaymentLinkModal, setCreatePaymentLinkModal] =
        useState<boolean>(false);

    return (
        <>
            <Dropdown.Button
                type={hideMainButton ? 'text' : 'default'}
                size="small"
                menu={{
                    items: [
                        {
                            onClick: () => {
                                setCreatePaymentLinkModal(true);
                            },
                            label: (
                                <Button
                                    className="text-left"
                                    block
                                    type="text"
                                    size="small"
                                >
                                    <EyeOutlined /> Sölusíða / greiðsluhlekkur
                                </Button>
                            ),
                            key: 'payment_link',
                        },
                        {
                            label: (
                                <Button
                                    className="text-left"
                                    block
                                    type="text"
                                    size="small"
                                    onClick={() => {
                                        setClipboardModal({
                                            uuid: product.uuid,
                                            type: 'product',
                                        });
                                    }}
                                >
                                    <Html5Outlined /> Iframe
                                </Button>
                            ),
                            key: 'iframe_code',
                        },

                        {
                            label: (
                                /* @ts-ignore */
                                <CopyToClipboard
                                    text={product.uuid}
                                    onCopy={() =>
                                        message.success(
                                            'Tókst, vörunúmer hefur verið afritað'
                                        )
                                    }
                                >
                                    <Button
                                        className="text-left"
                                        block
                                        type="text"
                                        size="small"
                                    >
                                        <CopyOutlined /> Afrita vörunúmer (uuid)
                                    </Button>
                                </CopyToClipboard>
                            ),
                            key: 'copy_uuid',
                        },
                    ],
                }}
            >
                {!hideMainButton && (
                    <a href={`/breyta_voru/${product.uuid}`}>
                        <EditOutlined /> Breyta
                    </a>
                )}
            </Dropdown.Button>

            {!!clipboardModal && (
                <CopyIframeCodeModal
                    uuid={clipboardModal.uuid}
                    type={clipboardModal.type}
                    open={!!clipboardModal}
                    onCancel={() => {
                        setClipboardModal(false);
                    }}
                ></CopyIframeCodeModal>
            )}

            {!!createPaymentLinkModal && (
                <Modal
                    title={product.title}
                    open={!!createPaymentLinkModal}
                    onCancel={() => setCreatePaymentLinkModal(false)}
                    footer={null}
                >
                    <CreatePaymentLink product={product}></CreatePaymentLink>
                </Modal>
            )}
        </>
    );
}
