import React from 'react';
import { ExternalDeliveryResponse } from '../interfaces/shipping';

type Props = {
    externalDeliveryRes: ExternalDeliveryResponse | undefined;
};

export function DisplayExternalDeliveryResponse({
    externalDeliveryRes,
}: Props) {
    return (
        <div>
            {externalDeliveryRes && (
                <>
                    {externalDeliveryRes.title}
                    <div>
                        {externalDeliveryRes.postal_code && (
                            <span>
                                {externalDeliveryRes.postal_code &&
                                    externalDeliveryRes.postal_code}
                            </span>
                        )}{' '}
                        {externalDeliveryRes.town && (
                            <span>
                                {externalDeliveryRes.town &&
                                    externalDeliveryRes.town}
                            </span>
                        )}
                    </div>
                </>
            )}
        </div>
    );
}
