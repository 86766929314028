import React, { useEffect, useState } from 'react';
import Order from '../../interfaces/order';
import { Button, List, Space, Avatar, Card } from 'antd';
import translate from '../../translate/translate';
import { displayCurrencyPrice, getDeliveryIcon } from '../../utils';

import axios from 'axios';
import { RetweetOutlined } from '@ant-design/icons';
import ReactHtmlParser from 'react-html-parser';
import { ExternalDeliveryResponse } from '../../interfaces/shipping';
import { DisplayExternalDeliveryResponse } from '../../utils/shippingUtils';

type Props = {
    order: Order;
};

interface IWindow extends Window {
    uuid?: string;
    me?: number;
}

const w: IWindow = window;

export default function OrderDetails({ order }: Props) {
    const [externalDeliveryRes, setExternalDeliveryRes] =
        useState<ExternalDeliveryResponse>();
    const [orderDetails, setOrderDetails] = useState<Order>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios
            .get('/verslun/api/get_confirmed_order/' + order.uuid + '/')
            .then((rsp) => {
                const _orderDetails = rsp.data;

                setOrderDetails(_orderDetails);
                setLoading(false);

                if (_orderDetails?.external_delivery_location_id) {
                    axios
                        .post('/shipping/api/delivery_option_details/', {
                            location_id:
                                _orderDetails.external_delivery_location_id,
                            shop_uuid: _orderDetails.shop.uuid,
                            shipping_provider:
                                _orderDetails.delivery_option
                                    ?.shipping_provider,
                            postal_code:
                                _orderDetails.delivery_postal_code ||
                                _orderDetails.customer?.postal_code ||
                                '',
                        })
                        .then((rsp) => {
                            setExternalDeliveryRes(rsp.data);
                        });
                }
            });

        return () => {
            setOrderDetails(undefined);
            setExternalDeliveryRes(undefined);
            setLoading(true);
        };
    }, [order]);

    return (
        <div>
            <div>
                {w.me === 1 && (
                    <Button href={`/bakendi/shop/order/${order.uuid}/change/`}>
                        Bakendi
                    </Button>
                )}
                <List
                    loading={loading}
                    dataSource={orderDetails?.products || []}
                    renderItem={(order_product) => {
                        const { product } = order_product;

                        let intro = product.description;

                        //Sometimes the description is a html string. If so then we need the first 100 characters
                        const isHtmlRegex = /<[a-z][\s\S]*>/i;
                        const isHtml = isHtmlRegex.test(intro);

                        if (isHtml) {
                            try {
                                const parser = new DOMParser();
                                const htmlDoc = parser.parseFromString(
                                    intro,
                                    'text/html'
                                );
                                intro = htmlDoc.body.textContent || '';

                                if (intro.length > 900) {
                                    intro = intro.slice(0, 900);
                                }
                            } catch (e) {
                                console.error(e);
                            }
                        }

                        return (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={
                                        <Avatar
                                            size="large"
                                            src={product.main_image.image}
                                        />
                                    }
                                    title={
                                        <>
                                            <div>
                                                {(order_product.quantity || 1) >
                                                    1 && (
                                                    <span
                                                        style={{
                                                            fontSize: 14,
                                                            textAlign: 'right',
                                                        }}
                                                    >
                                                        {order_product.quantity}
                                                        x{' '}
                                                    </span>
                                                )}
                                                {product.title}
                                            </div>
                                            <div
                                                style={{
                                                    fontWeight: 'bold',
                                                    fontSize: 14,
                                                    textAlign: 'right',
                                                    position: 'absolute',
                                                    top: 10,
                                                    right: 0,
                                                    backgroundColor: 'white',
                                                    padding: '0 10px',
                                                }}
                                            >
                                                {order &&
                                                    displayCurrencyPrice(
                                                        order_product.price,
                                                        orderDetails?.currency_code ||
                                                            'ISK'
                                                    )}

                                                {order &&
                                                    (order_product.quantity ||
                                                        1) > 1 &&
                                                    order_product.price && (
                                                        <div
                                                            style={{
                                                                fontWeight:
                                                                    'normal',
                                                                fontSize: 12,
                                                            }}
                                                        >
                                                            (
                                                            {
                                                                order_product.quantity
                                                            }
                                                            x{' '}
                                                            {displayCurrencyPrice(
                                                                order_product.price /
                                                                    (order_product.quantity ||
                                                                        1),
                                                                orderDetails?.currency_code ||
                                                                    'ISK'
                                                            )}
                                                            )
                                                        </div>
                                                    )}
                                            </div>
                                        </>
                                    }
                                    description={
                                        <>
                                            <div
                                                style={{
                                                    textAlign: 'justify',
                                                    paddingRight: 15,
                                                }}
                                            >
                                                <Space direction="vertical">
                                                    {product?.confirmation_message && (
                                                        <>
                                                            <Card
                                                                style={{
                                                                    background:
                                                                        '#f3f3f3',
                                                                    whiteSpace:
                                                                        'pre-wrap',
                                                                }}
                                                            >
                                                                {
                                                                    ReactHtmlParser(
                                                                        product?.confirmation_message
                                                                    ) as React.ReactNode
                                                                }
                                                            </Card>
                                                        </>
                                                    )}

                                                    {product.type ===
                                                        'GIFT_CERTIFICATE' && (
                                                        <div>
                                                            <Button type="primary">
                                                                <a
                                                                    href={`/askrift/pdf_bref/${order_product.gift_certificate_code}/`}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    Prenta
                                                                    gjafabréf
                                                                </a>
                                                            </Button>
                                                        </div>
                                                    )}

                                                    {order_product.custom_form_entry && (
                                                        <div
                                                            style={{
                                                                border: '1px solid gray',
                                                                padding: '10px',
                                                                borderRadius: 5,
                                                            }}
                                                        >
                                                            {order_product.custom_form_entry.text_answers.map(
                                                                (t, key) => {
                                                                    return (
                                                                        <div
                                                                            key={
                                                                                key
                                                                            }
                                                                        >
                                                                            <strong>
                                                                                {
                                                                                    t
                                                                                        .parent
                                                                                        .question
                                                                                }

                                                                                :
                                                                            </strong>{' '}
                                                                            {
                                                                                t.answer
                                                                            }
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                        </div>
                                                    )}

                                                    <>
                                                        {product.cron_transaction && (
                                                            <div className="productIntervalDetails">
                                                                <RetweetOutlined />{' '}
                                                                {translate(
                                                                    'subscription'
                                                                )}{' '}
                                                                :{' '}
                                                                {
                                                                    product
                                                                        .cron_transaction
                                                                        .interval
                                                                }{' '}
                                                                {product
                                                                    .cron_transaction
                                                                    .interval_type ===
                                                                'WEEK'
                                                                    ? translate(
                                                                          'weeks',
                                                                          'is'
                                                                      )
                                                                    : product
                                                                            .cron_transaction
                                                                            .interval_type ===
                                                                        'YEAR'
                                                                      ? translate(
                                                                            'years',
                                                                            'is'
                                                                        )
                                                                      : translate(
                                                                            'months',
                                                                            'is'
                                                                        )}{' '}
                                                                {translate(
                                                                    'apart',
                                                                    'is'
                                                                )}
                                                            </div>
                                                        )}
                                                    </>
                                                </Space>
                                            </div>
                                        </>
                                    }
                                />
                            </List.Item>
                        );
                    }}
                ></List>
                <List>
                    {!!orderDetails?.final_shipping_price && (
                        <>
                            <List.Item>
                                <List.Item.Meta
                                    title={translate('product_price', 'is')}
                                />
                                <div>
                                    {displayCurrencyPrice(
                                        orderDetails?.final_product_price || 0,
                                        orderDetails.currency_code || 'ISK'
                                    )}
                                </div>
                            </List.Item>

                            <List.Item>
                                <List.Item.Meta
                                    title={translate('shipping_price', 'is')}
                                />
                                <div>
                                    {displayCurrencyPrice(
                                        orderDetails?.final_shipping_price,
                                        orderDetails.currency_code || 'ISK'
                                    )}
                                </div>
                            </List.Item>
                        </>
                    )}

                    <List.Item>
                        <List.Item.Meta title={translate('total', 'is')} />
                        <div>
                            {order &&
                                displayCurrencyPrice(
                                    orderDetails?.final_full_price || 0,
                                    orderDetails?.currency_code || 'ISK'
                                )}
                        </div>
                    </List.Item>

                    {orderDetails?.delivery_option && (
                        <>
                            <div
                                style={{
                                    marginTop: 20,
                                    fontWeight: 'bold',
                                }}
                            >
                                {translate('delivery_method', 'is')}
                            </div>
                            <List.Item style={{ fontSize: 12 }}>
                                {getDeliveryIcon(
                                    orderDetails.delivery_option
                                        .shipping_provider,
                                    35
                                )}

                                <div
                                    style={{
                                        padding: '0 15px',
                                        width: '100%',
                                    }}
                                >
                                    <div>
                                        {orderDetails.delivery_option.title}
                                    </div>
                                    <div>
                                        {externalDeliveryRes && (
                                            <DisplayExternalDeliveryResponse
                                                externalDeliveryRes={
                                                    externalDeliveryRes
                                                }
                                            ></DisplayExternalDeliveryResponse>
                                        )}
                                    </div>
                                </div>
                            </List.Item>
                        </>
                    )}
                </List>
            </div>

            <Button
                href={`/pontun/${order.uuid}/`}
                target="_blank"
                type="ghost"
                size="large"
                onClick={() => {}}
                block
            >
                Opna staðfestingarsíðu
            </Button>
        </div>
    );
}
