import React from 'react';
import Product, {
    CustomForm,
    RepeatCheckoutProduct,
} from '../../interfaces/product';
import TextArea from 'antd/lib/input/TextArea';
import { Form, Input, InputNumber } from 'antd';
import translate from '../../translate/translate';

interface Props {
    products: RepeatCheckoutProduct[] | Product[];
    selectedLanguage: 'is' | 'en';
    whenToCollect: CustomForm['when_to_collect'];
}

export default function CustomFormItems({ products, selectedLanguage }: Props) {
    const required = [
        {
            required: true,
            message: translate('field_required', selectedLanguage),
        },
    ];
    const customForms = products
        .map((p) => {
            const repeatCheckoutProduct = p as RepeatCheckoutProduct;
            const product = p as Product;

            if (repeatCheckoutProduct.details?.custom_form) {
                return repeatCheckoutProduct.details?.custom_form;
            } else if (product.custom_form) {
                return product.custom_form;
            } else {
                return null;
            }
        })
        .filter((cfi) => cfi !== null);
    return (
        <>
            {customForms
                .filter((c) => c.when_to_collect === 'CHECKOUT_PAGE')
                .map((customForm: CustomForm, index) => {
                    const formSlug = customForm?.slug || customForm?.uuid;

                    return (
                        <div key={index}>
                            {/* Text inputs */}

                            {customForm.texts.map((textInput) => {
                                return (
                                    <Form.Item
                                        className={textInput.class_name || ''}
                                        label={textInput.question}
                                        labelCol={{ span: 24 }}
                                        key={textInput.uuid}
                                        normalize={
                                            textInput.force_uppercase
                                                ? (value: string) =>
                                                      value.toUpperCase()
                                                : undefined
                                        }
                                        rules={
                                            textInput.required
                                                ? [
                                                      {
                                                          required:
                                                              textInput.required,
                                                          message: translate(
                                                              'field_required',
                                                              selectedLanguage
                                                          ),
                                                      },
                                                  ]
                                                : []
                                        }
                                        name={[
                                            'custom_data',
                                            formSlug,
                                            textInput.slug || textInput.uuid,
                                        ]}
                                    >
                                        {textInput.multi_line &&
                                        textInput.type === 'TEXT' ? (
                                            <TextArea
                                                minLength={textInput.min_length}
                                                maxLength={textInput.max_length}
                                                style={
                                                    textInput.force_uppercase
                                                        ? {
                                                              textTransform:
                                                                  'uppercase',
                                                          }
                                                        : {}
                                                }
                                            ></TextArea>
                                        ) : !textInput.multi_line &&
                                          textInput.type === 'TEXT' ? (
                                            <Input
                                                style={
                                                    textInput.force_uppercase
                                                        ? {
                                                              textTransform:
                                                                  'uppercase',
                                                          }
                                                        : {}
                                                }
                                                minLength={textInput.min_length}
                                                maxLength={textInput.max_length}
                                            ></Input>
                                        ) : (
                                            <InputNumber
                                                style={
                                                    textInput.force_uppercase
                                                        ? {
                                                              textTransform:
                                                                  'uppercase',
                                                          }
                                                        : {}
                                                }
                                                minLength={textInput.min_length}
                                                maxLength={textInput.max_length}
                                            ></InputNumber>
                                        )}
                                    </Form.Item>
                                );
                            })}

                            {/* Textarea inputs */}
                        </div>
                    );
                })}
        </>
    );
}
