import { Button, Card, Modal, Table } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { ShopifyOrder } from './shopifyTypes';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import axios from 'axios';
import AuthContext from '../../authContext';
import dayjs from 'dayjs';
import {
    CodeOutlined,
    DeleteOutlined,
    EyeFilled,
    EyeOutlined,
} from '@ant-design/icons';

type Props = {};

export default function ShopifyOrderOverview({}: Props) {
    const [orders, setOrders] = useState<ShopifyOrder[]>([]);
    const [json, setJSON] = useState<ShopifyOrder | false>(false);
    const [deleteOrder, setDeleteOrder] = useState<string | false>(false);
    const { selectedShop } = useContext(AuthContext);

    useEffect(() => {
        if (selectedShop)
            axios
                .get(`/verslun/api/get_shopify_orders/${selectedShop.uuid}/`)
                .then((res) => {
                    setOrders(res.data);
                });
    }, [selectedShop]);

    const columns = [
        {
            title: 'Order number',
            dataIndex: 'order_number',
            key: 'order_number',
            render: (text: string, record: ShopifyOrder) => (
                <a href={`/shopify/order/${record.id}`}>{text}</a>
            ),
        },
        {
            title: 'Order status',
            dataIndex: 'order_status',
            key: 'order_status',
            render: (text: string, record: ShopifyOrder) => (
                <div
                    style={{
                        textTransform: 'capitalize',
                    }}
                >
                    {record.fulfillment_status}
                </div>
            ),
        },
        {
            title: 'Customer',
            dataIndex: 'customer',
            key: 'customer',
            render: (text: string, record: ShopifyOrder) => (
                <div
                    style={{
                        // Line through if canceled
                        textDecoration: record.cancelled_at
                            ? 'line-through'
                            : 'none',
                    }}
                >
                    {record.customer.first_name} {record.customer.last_name}
                </div>
            ),
        },
        {
            title: 'Shipping title',
            dataIndex: 'shipping_lines',
            key: 'shipping_lines',
            render: (text: string, record: ShopifyOrder) => {
                if (record.shipping_lines.length === 0) {
                    return (
                        <div
                            style={{
                                // Line through if canceled
                                textDecoration: record.cancelled_at
                                    ? 'line-through'
                                    : 'none',
                            }}
                        >
                            Engin sending
                        </div>
                    );
                }

                return (
                    <div
                        style={{
                            // Line through if canceled
                            textDecoration: record.cancelled_at
                                ? 'line-through'
                                : 'none',
                        }}
                    >
                        {record.shipping_lines[0].title}
                    </div>
                );
            },
        },
        {
            title: 'Total price',
            dataIndex: 'total_price',
            key: 'total_price',
            render: (text: string, record: ShopifyOrder) => (
                <div
                    style={{
                        // Line through if canceled
                        textDecoration: record.cancelled_at
                            ? 'line-through'
                            : 'none',
                    }}
                >
                    {record.total_price} {record.currency}
                </div>
            ),
        },
        {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text: string, record: ShopifyOrder) => (
                <div
                    style={{
                        // Line through if canceled
                        textDecoration: record.cancelled_at
                            ? 'line-through'
                            : 'none',
                    }}
                >
                    {dayjs(record.created_at).format('D. MMM - YYYY HH:mm')}
                </div>
            ),
        },
        {
            title: 'Skoða JSON',
            dataIndex: 'id',
            key: 'id',
            render: (text: string, record: ShopifyOrder) => {
                return (
                    <Button
                        type="text"
                        onClick={() => {
                            setJSON(record);
                        }}
                    >
                        <CodeOutlined />
                    </Button>
                );
            },
        },
        {
            title: 'Eyða pöntun',
            dataIndex: 'delete',
            key: 'delete',
            render: (text: string, record: ShopifyOrder) => {
                return (
                    <Button
                        hidden={record.cancelled_at ? true : false}
                        type="text"
                        danger
                        onClick={() => {
                            setDeleteOrder(record.id.toString());
                        }}
                    >
                        <DeleteOutlined />
                    </Button>
                );
            },
        },
    ];

    return (
        <div>
            <Card
                className="basePageCard"
                title={
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <img
                                style={{ height: 35, marginRight: 10 }}
                                src="/static/images/svg/shopify.svg"
                                alt=""
                            />{' '}
                            Shopify pantanir
                        </div>
                    </div>
                }
            >
                <Table
                    pagination={false}
                    dataSource={orders}
                    columns={columns}
                ></Table>
            </Card>

            <Modal
                open={deleteOrder !== false}
                onCancel={() => setDeleteOrder(false)}
                title="Eyða pöntun"
                footer={null}
            >
                <p>Ertu viss um að þú viljir eyða þessari pöntun?</p>

                {selectedShop && (
                    <Button
                        onClick={() => {
                            axios
                                .post(
                                    `/verslun/api/delete_shopify_order/${selectedShop.uuid}/`,
                                    {
                                        order_id: deleteOrder,
                                    }
                                )
                                .then(() => {
                                    setDeleteOrder(false);
                                    setOrders(
                                        orders.filter(
                                            (order) =>
                                                order.id.toString() !==
                                                deleteOrder
                                        )
                                    );
                                });
                        }}
                        danger
                    >
                        Eyða
                    </Button>
                )}
            </Modal>

            <Modal
                open={json !== false}
                onCancel={() => setJSON(false)}
                title="JSON"
                footer={null}
            >
                {json && (
                    // @ts-ignore
                    <SyntaxHighlighter
                        language="json"
                        style={dracula}
                        showLineNumbers={true}
                    >
                        {json && JSON.stringify(json, null, 2)}
                    </SyntaxHighlighter>
                )}
            </Modal>
        </div>
    );
}
