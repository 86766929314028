import { Badge, Button, Modal, Select, Timeline } from 'antd';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import Customer, { StaffComment } from '../../../interfaces/customer';
import AuthContext from '../../../authContext';
import axios from 'axios';
import Email from '../../../interfaces/email';
import {
    SendOutlined,
    MessageOutlined,
    CreditCardOutlined,
    BranchesOutlined,
} from '@ant-design/icons';
import { RequestLogOverview } from '../../../interfaces/requestLog';
import Payment from '../../../interfaces/payment';
import PaymentTransactionDetails from '../../../components/SaltPay/PaymentTransactionDetails';
import LogDetails from '../../../components/LogOverview/LogDetails';
import SentEmailDetails from '../../../components/EmailOverview/SentEmailDetails';
import SaltPay from '../../../components/SaltPay/SaltPay';
import LogOverview from '../../../components/LogOverview/LogOverview';
import Order from '../../../interfaces/order';
import OrderDetails from '../../../components/OrderDetails/OrderDetails';

type Props = {
    customer: Customer;
};

type CustomPayment = Payment & { category: string };
type CustomEmail = Email & { category: string };
type CustomRequestLogOverview = RequestLogOverview & { category: string };
type CustomStaffComment = StaffComment & { category: string };
type CustomOrder = Order & { category: string };

export default function CustomerTimeline({ customer }: Props) {
    const { selectedShop } = useContext(AuthContext);

    const [emails, setEmails] = useState<CustomEmail[]>([]);
    const [apiResponses, setApiResponses] = useState<
        CustomRequestLogOverview[]
    >([]);
    const [paymentRequests, setPaymentRequests] = useState<CustomPayment[]>([]);
    const [selectedServices, setSelectedServices] = useState<string[]>([]);
    const [featured, setFeatured] = useState<RequestLogOverview | false>(false);
    const [emailModal, setEmailModal] = useState<Email | false>(false);
    const [orders, setOrders] = useState<CustomOrder[]>([]);
    const [comments, setComments] = useState<CustomStaffComment[]>([]);

    const [cardLogDetailsModal, setCardLogDetailsModal] = useState(false);
    const [logDetailsModal, setLogDetailsModal] = useState(false);
    const [orderDetailsModal, setOrderDetailsModal] = useState<Order | false>(
        false
    );

    useEffect(() => {
        if (selectedShop) {
            setComments(
                (customer.staff_comments || []).map((c) => {
                    return {
                        ...c,
                        category: 'staffComment',
                    };
                })
            );

            axios
                .get(
                    '/payment/api/payment_requests/?ordering=-created&customer=' +
                        customer.uuid
                )
                .then((rsp) => {
                    setPaymentRequests(
                        rsp.data.results.map((p: Payment) => {
                            return {
                                ...p,
                                category: 'paymentRequest',
                            };
                        })
                    );
                });

            axios
                .post(`/tolvupostur/api/get_user_emails/`, {
                    customer_uuid: customer.uuid,
                    shop_id: customer.shop.id,
                })
                .then((rsp) => {
                    setEmails(
                        rsp.data.map((e: Email) => {
                            return {
                                ...e,
                                category: 'email',
                            };
                        })
                    );
                });

            axios
                .get(
                    `/api/external_request_logs?shop__uuid=${
                        selectedShop?.uuid
                    }&ordering=-created&page_size=${50}${
                        '&customers=' + customer.uuid
                    }`
                )
                .then((rsp) => {
                    setApiResponses(
                        rsp.data.results.map((r: RequestLogOverview) => {
                            return {
                                ...r,
                                category: 'apiResponse',
                            };
                        })
                    );
                });

            //         if (!selectedShop) return;

            // let queryParam = hideFulfilled ? '&is_fulfilled=false' : '';

            // if (selectedProductUUIDs.length > 0) {
            //     queryParam += `&products__product__uuid__in=${selectedProductUUIDs.join(
            //         ','
            //     )}`;
            // }
            // setQueryParams(
            //     `/verslun/api/orders/?shop__uuid=${selectedShop.uuid}&page_size=25&ordering=-created${queryParam}`
            // );

            axios
                .get(
                    '/verslun/api/orders/?shop__uuid=' +
                        selectedShop.uuid +
                        '&page_size=25&ordering=-created&customer__uuid=' +
                        customer.uuid
                )
                .then((rsp) => {
                    setOrders(
                        rsp.data.results.map((o: Order) => {
                            return {
                                ...o,
                                category: 'order',
                            };
                        })
                    );
                });
        }
    }, [selectedShop, customer]);

    const combined = [
        ...(comments || []),
        ...(emails || []),
        ...(apiResponses || []),
        ...(paymentRequests || []),
        ...(orders || []),
    ];

    return (
        <div id="customerTimeline">
            <Select
                size="small"
                mode="tags"
                style={{ width: '100%', marginBottom: 10 }}
                placeholder="Sía eftir þjónustu"
                maxTagCount={2}
                onChange={(value) => {
                    setSelectedServices(value);
                }}
            >
                <Select.Option key="comments" value={'comments'}>
                    Athugasemdir
                </Select.Option>

                <Select.Option key="logs" value={'logs'}>
                    Loggar
                </Select.Option>

                <Select.Option
                    key="card_transactions"
                    value={'cardTransactions'}
                >
                    Kortafærslur
                </Select.Option>

                <Select.Option key="emails" value={'emails'}>
                    Tölvupóstar
                </Select.Option>

                <Select.Option key="orders" value={'orders'}>
                    Pantanir
                </Select.Option>
            </Select>

            {selectedServices.includes('cardTransactions') && (
                <>
                    <Button
                        onClick={() => {
                            setCardLogDetailsModal(true);
                        }}
                        block
                    >
                        <CreditCardOutlined></CreditCardOutlined> Nánar
                    </Button>
                </>
            )}

            {selectedServices.includes('logs') && (
                <>
                    <Button
                        onClick={() => {
                            setLogDetailsModal(true);
                        }}
                        block
                    >
                        Nánar
                    </Button>
                </>
            )}

            <Timeline
                mode="left"
                style={{
                    marginLeft: 10,
                    marginTop: 20,
                }}
            >
                {combined
                    .sort((a, b) =>
                        new Date(a.created) > new Date(b.created) ? -1 : 0
                    )
                    .map((obj, key: number) => {
                        if (
                            obj.category === 'paymentRequest' &&
                            (selectedServices.includes('cardTransactions') ||
                                selectedServices.length === 0)
                        ) {
                            const paymentRequest = obj as CustomPayment;
                            return (
                                <Timeline.Item
                                    color="gray"
                                    className="repeat-timeline-item"
                                    key={key}
                                    style={{
                                        fontSize: 12,
                                    }}
                                    dot={
                                        <CreditCardOutlined
                                            style={{
                                                fontSize: 18,
                                            }}
                                        />
                                    }
                                >
                                    <div>
                                        <span className="timeline-item-header">
                                            <span>Kortasamskipti</span>
                                            <span
                                                style={{
                                                    color: 'lightgray',
                                                    fontSize: 12,
                                                }}
                                            >
                                                {dayjs(obj.created).format(
                                                    'D. MMM YYYY HH:mm'
                                                )}
                                            </span>
                                        </span>
                                    </div>
                                    <div className="timeline-item-body">
                                        <PaymentTransactionDetails
                                            paymentTransaction={paymentRequest}
                                            isPublic={false}
                                            language={'is'}
                                            isTimeline={true}
                                            color={'#1890ff'}
                                            onRefundSuccess={() => {
                                                setPaymentRequests([
                                                    ...paymentRequests.filter(
                                                        (t) =>
                                                            t.id !==
                                                            paymentRequest.id
                                                    ),
                                                    {
                                                        ...paymentRequest,
                                                        is_refunded: true,
                                                    },
                                                ]);
                                            }}
                                        ></PaymentTransactionDetails>
                                    </div>
                                </Timeline.Item>
                            );
                        }

                        if (
                            obj.category === 'order' &&
                            (selectedServices.includes('orders') ||
                                selectedServices.length === 0)
                        ) {
                            const order = obj as CustomOrder;

                            return (
                                <Timeline.Item
                                    className="repeat-timeline-item"
                                    color="gray"
                                    key={key}
                                    style={{
                                        fontSize: 12,
                                    }}
                                    dot={
                                        <img
                                            alt="Pöntun icon"
                                            src="/static/images/svg/shopping-cart.svg"
                                            style={{ width: 20, opacity: 0.25 }}
                                        ></img>
                                    }
                                >
                                    <div>
                                        <span className="timeline-item-header">
                                            <span>Pöntun</span>
                                            <span
                                                style={{
                                                    color: 'lightgray',
                                                    fontSize: 12,
                                                }}
                                            >
                                                {dayjs(order.created).format(
                                                    'D. MMM YYYY HH:mm'
                                                )}
                                            </span>
                                        </span>
                                    </div>
                                    {order.created_by && (
                                        <div
                                            style={{
                                                color: 'lightgray',
                                                fontSize: 11,
                                            }}
                                        >
                                            ({order.created_by})
                                        </div>
                                    )}
                                    <div className="timeline-item-body">
                                        <Button
                                            style={{
                                                color: 'gray',
                                                background:
                                                    'rgba(0, 0, 0, 0.028)',
                                                fontSize: 12,
                                            }}
                                            size="small"
                                            type="text"
                                            onClick={() => {
                                                setOrderDetailsModal(order);
                                            }}
                                        >
                                            Nánar
                                        </Button>
                                    </div>
                                </Timeline.Item>
                            );
                        }

                        if (
                            obj.category === 'apiResponse' &&
                            (selectedServices.includes('logs') ||
                                selectedServices.length === 0)
                        ) {
                            const apiResponse = obj as CustomRequestLogOverview;
                            return (
                                <Timeline.Item
                                    className="repeat-timeline-item"
                                    color={'gray'}
                                    key={key}
                                    style={{
                                        fontSize: 12,
                                    }}
                                    dot={
                                        <BranchesOutlined
                                            style={{
                                                fontSize: 20,
                                            }}
                                        />
                                    }
                                >
                                    <div>
                                        <span className="timeline-item-header">
                                            <span>
                                                Samskipti við önnur kerfi
                                            </span>
                                            <span
                                                style={{
                                                    color: 'lightgray',
                                                    fontSize: 12,
                                                }}
                                            >
                                                {dayjs(
                                                    apiResponse.created
                                                ).format('D. MMM YYYY HH:mm')}
                                            </span>
                                        </span>
                                    </div>
                                    <div className="timeline-item-body">
                                        <Button
                                            style={{
                                                color: 'gray',
                                                background:
                                                    'rgba(0, 0, 0, 0.028)',
                                                fontSize: 12,
                                            }}
                                            size="small"
                                            type="text"
                                            onClick={() => {
                                                setFeatured(apiResponse);
                                            }}
                                        >
                                            Nánar
                                        </Button>
                                        <div>
                                            <span>
                                                <Badge
                                                    status={
                                                        apiResponse.success
                                                            ? 'success'
                                                            : 'error'
                                                    }
                                                ></Badge>{' '}
                                                <span>
                                                    {
                                                        (
                                                            apiResponse.service ||
                                                            ''
                                                        ).split('')[0]
                                                    }
                                                </span>
                                                <span
                                                    style={{
                                                        textTransform:
                                                            'lowercase',
                                                    }}
                                                >
                                                    {apiResponse.service
                                                        ?.split('')
                                                        .slice(1)
                                                        .join('')}
                                                </span>
                                            </span>{' '}
                                            {apiResponse.method && (
                                                <>
                                                    {' - '}
                                                    {apiResponse.method.toUpperCase()}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </Timeline.Item>
                            );
                        } else if (
                            obj.category === 'email' &&
                            (selectedServices.includes('emails') ||
                                selectedServices.length === 0)
                        ) {
                            const email = obj as CustomEmail;
                            return (
                                <Timeline.Item
                                    className="repeat-timeline-item"
                                    color="gray"
                                    key={key}
                                    style={{
                                        fontSize: 12,
                                    }}
                                    dot={
                                        <SendOutlined
                                            style={{
                                                fontSize: 18,
                                            }}
                                        />
                                    }
                                >
                                    <div>
                                        <span className="timeline-item-header">
                                            <span>Tölvupóstur sendur</span>
                                            <span
                                                style={{
                                                    color: 'lightgray',
                                                    fontSize: 12,
                                                }}
                                            >
                                                {dayjs(email.created).format(
                                                    'D. MMM YYYY HH:mm'
                                                )}
                                            </span>
                                        </span>
                                    </div>

                                    <Button
                                        type="text"
                                        size="small"
                                        style={{
                                            color: 'gray',
                                            background: 'rgba(0, 0, 0, 0.028)',
                                            fontSize: 12,
                                        }}
                                        onClick={() => {
                                            console.log(email);
                                            setEmailModal(email);
                                        }}
                                    >
                                        Nánar
                                    </Button>
                                    <div
                                        style={{}}
                                        className="timeline-item-body"
                                    >
                                        <div
                                            style={{
                                                color: 'lightgray',
                                            }}
                                        >
                                            {email.to ? email.to : ''}
                                        </div>
                                        {email.subject}

                                        <br />
                                    </div>
                                </Timeline.Item>
                            );
                        }
                        if (
                            obj.category === 'staffComment' &&
                            (selectedServices.includes('comments') ||
                                selectedServices.length === 0)
                        ) {
                            const comment = obj as CustomStaffComment;
                            return (
                                <Timeline.Item
                                    className="repeat-timeline-item"
                                    key={key}
                                    style={{
                                        fontSize: 12,
                                    }}
                                    dot={
                                        <MessageOutlined
                                            style={{
                                                fontSize: 18,
                                            }}
                                        />
                                    }
                                    color="gray"
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <div>
                                            <span
                                                style={{
                                                    color: '#aaa',
                                                    width: '100%',
                                                    display: 'flex',
                                                    gap: 10,
                                                }}
                                            >
                                                <span>Athugasemd</span>
                                                <span
                                                    style={{
                                                        color: 'lightgray',
                                                        fontSize: 12,
                                                    }}
                                                >
                                                    {dayjs(
                                                        comment.created
                                                    ).format(
                                                        'D. MMM YYYY HH:mm'
                                                    )}
                                                </span>
                                            </span>
                                        </div>
                                    </div>

                                    <div
                                        style={{}}
                                        className="timeline-item-body staffComment"
                                    >
                                        <span className="staffCommentUser">
                                            {comment.user
                                                ? comment.user + ': '
                                                : ''}
                                        </span>
                                        {comment.message}
                                    </div>
                                </Timeline.Item>
                            );
                        }
                    })}
            </Timeline>

            <Modal
                onCancel={() => {
                    setFeatured(false);
                }}
                open={!!featured}
                footer={null}
            >
                <div style={{ width: '100%' }}>
                    {!!featured && <LogDetails log={featured}></LogDetails>}
                </div>
            </Modal>

            {selectedShop && emailModal && (
                <Modal
                    open={true}
                    footer={null}
                    onCancel={() => {
                        setEmailModal(false);
                    }}
                >
                    <SentEmailDetails
                        email={emailModal}
                        shop={selectedShop}
                    ></SentEmailDetails>
                </Modal>
            )}

            {selectedShop && cardLogDetailsModal && (
                <Modal
                    title="Samskipti við færsluhirði"
                    open={cardLogDetailsModal}
                    onCancel={() => {
                        setCardLogDetailsModal(false);
                    }}
                    footer={null}
                >
                    <SaltPay
                        isPublic={false}
                        columns={['date', 'card_number', 'type']}
                        url={`/payment/api/payment_requests/?ordering=-created&customer=${customer.uuid}`}
                    ></SaltPay>
                </Modal>
            )}

            {selectedShop && logDetailsModal && (
                <Modal
                    title="Samskipti við önnur kerfi"
                    open={logDetailsModal}
                    onCancel={() => {
                        setLogDetailsModal(false);
                    }}
                    footer={null}
                >
                    <LogOverview
                        admin={true}
                        shop={selectedShop}
                        customer={customer.uuid}
                    ></LogOverview>
                </Modal>
            )}

            {selectedShop && orderDetailsModal && (
                <>
                    <Modal
                        title="Pöntun"
                        open={!!orderDetailsModal}
                        onCancel={() => {
                            setOrderDetailsModal(false);
                        }}
                        footer={null}
                    >
                        <OrderDetails order={orderDetailsModal}></OrderDetails>
                    </Modal>
                </>
            )}
        </div>
    );
}
